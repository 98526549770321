// Generated by Framer (937e5b9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/MXhKL9RD2";

const enabledGestures = {yUvhYd6Rv: {hover: true}};

const cycleOrder = ["yUvhYd6Rv", "msnkPl8FO", "dXfOzhavU"];

const variantClassNames = {dXfOzhavU: "framer-v-urmu8j", msnkPl8FO: "framer-v-q9a8vv", yUvhYd6Rv: "framer-v-15cri9b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0, ease: [0, 0, 1, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Contact Link - Desktop": "yUvhYd6Rv", "Contact Link - Mobile": "dXfOzhavU", "Contact Link - Tablet": "msnkPl8FO"}

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, MToWS_PS1: text ?? props.MToWS_PS1 ?? "Text", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yUvhYd6Rv", XW3ZJMueV: link ?? props.XW3ZJMueV} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MToWS_PS1, XW3ZJMueV, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yUvhYd6Rv", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-6ylAj", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-15cri9b", className)} data-framer-name={"Contact Link - Desktop"} layoutDependency={layoutDependency} layoutId={"yUvhYd6Rv"} ref={ref} style={{...style}} {...addPropertyOverrides({"yUvhYd6Rv-hover": {"data-framer-name": undefined}, dXfOzhavU: {"data-framer-name": "Contact Link - Mobile"}, msnkPl8FO: {"data-framer-name": "Contact Link - Tablet"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-pzxrpj"} data-styles-preset={"MXhKL9RD2"} style={{"--framer-text-color": "var(--extracted-r6o4lv)"}}><Link href={XW3ZJMueV} openInNewTab smoothScroll={false}><motion.a>Text</motion.a></Link></motion.p></React.Fragment>} className={"framer-1vos7oz"} data-framer-name={"Singel 258, 1016 AB Amsterdam, Netherlands"} layoutDependency={layoutDependency} layoutId={"tP_yBym7t"} style={{"--extracted-r6o4lv": "var(--token-99c2a2fb-c572-4a21-9b3a-79ca65ab414d, rgb(18, 18, 18))", "--framer-paragraph-spacing": "0px"}} text={MToWS_PS1} variants={{"yUvhYd6Rv-hover": {"--extracted-r6o4lv": "rgb(99, 99, 99)"}}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-6ylAj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6ylAj .framer-1m4xw1k { display: block; }", ".framer-6ylAj .framer-15cri9b { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: wrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 500px; }", ".framer-6ylAj .framer-1vos7oz { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 500px; word-break: break-word; word-wrap: break-word; }", ".framer-6ylAj .framer-v-15cri9b .framer-15cri9b { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6ylAj .framer-15cri9b { gap: 0px; } .framer-6ylAj .framer-15cri9b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-6ylAj .framer-15cri9b > :first-child { margin-left: 0px; } .framer-6ylAj .framer-15cri9b > :last-child { margin-right: 0px; } }", ".framer-6ylAj.framer-v-q9a8vv .framer-1vos7oz { width: 400px; }", ".framer-6ylAj.framer-v-urmu8j .framer-15cri9b, .framer-6ylAj.framer-v-urmu8j .framer-1vos7oz { width: 300px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 81
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"msnkPl8FO":{"layout":["fixed","auto"]},"dXfOzhavU":{"layout":["fixed","auto"]},"oKniMxLIK":{"layout":["fixed","auto"]}}}
 * @framerVariables {"MToWS_PS1":"text","XW3ZJMueV":"link"}
 */
const FramerivFG2JW5l: React.ComponentType<Props> = withCSS(Component, css, "framer-6ylAj") as typeof Component;
export default FramerivFG2JW5l;

FramerivFG2JW5l.displayName = "Contact Links";

FramerivFG2JW5l.defaultProps = {height: 47, width: 81};

addPropertyControls(FramerivFG2JW5l, {variant: {options: ["yUvhYd6Rv", "msnkPl8FO", "dXfOzhavU"], optionTitles: ["Contact Link - Desktop", "Contact Link - Tablet", "Contact Link - Mobile"], title: "Variant", type: ControlType.Enum}, MToWS_PS1: {defaultValue: "Text", displayTextArea: false, title: "Text", type: ControlType.String}, XW3ZJMueV: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerivFG2JW5l, [...sharedStyle.fonts])